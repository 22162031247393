import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import './App.css';
import ModernizationAssessmentHome from './components/ModernizationAssessmentHome';
import ReverseEngineeringHome from './components/ReverseEngineeringHome';
import TrainingHome from './components/TrainingHome';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/modernization-assessment" element={<ModernizationAssessmentHome />} />
        <Route path="/reverse-engineering" element={<ReverseEngineeringHome />} />
        <Route path="/training" element={<TrainingHome />} />
      </Routes>
    </Router>
    </>
  );
  
}

export default App;
