import React from 'react';
import Button from '../reusables/Button';
import Image from '../reusables/Image';

function Card({card}) {

  const openUrl = () => {
    window.open(card.url, "_self");
  };

  return(
    <div className="card">

      <Image
      image={{
        src: card.imageSrc,
        alt: card.imageAlt,
        class: "card-image"
      }} />

      <h2 className="card-tagline">{card.tagline}</h2>

      <Button
      button={{
        class:"card-action",
        displayText: card.buttonText,
        onClick: openUrl
      }} />

    </div>
  );
}

export default Card;