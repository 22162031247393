import HyperLink from "./reusables/HyperLink";

function NavBar({ links, className }) {
    return(
        <div className={className}>
            {links.map((link, index) => (
                <HyperLink key={index} link={link} />
            ))}
        </div>
    );
}

export default NavBar;
