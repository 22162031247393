import HyperLink from "./reusables/HyperLink";

function Footer() {
    return(
        <>
        <div className="footer">
            <HyperLink
            link={{
                url:"#",
                target:"_self",
                displayText:"PalmDigitalz | India | 2024"
            }} />
        </div>
        </>
    );
}

export default Footer;