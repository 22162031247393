import { Helmet } from 'react-helmet';
import logo from '../logo.svg';
import { useEffect, useRef, useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Card from "./interactables/Card";
import Button from "./reusables/Button";
import modernizeImage from '../img/modernize.jpg'
import reverseEngImage from '../img/reverseEngineering.jpeg'
import trainingImage from '../img/training.jpeg'


function Home() {
    const scrollableRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');

    const timeSlots = ['10:00 AM', '11:30 AM', '1:00 PM', '3:00 PM', '5:00 PM'];

    // const openAppointmentSchedulerPopup = () => {
    //     setModalVisible(true);
    // };

    const closeAppointmentSchedulerPopup = () => {
        setModalVisible(false);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    useEffect(() => {
        const container = scrollableRef.current;
        if (container) {
          const handleScroll = () => {
            const elements = container.querySelectorAll('p');
            const middle = container.offsetHeight / 2;
            elements.forEach((el) => {
              const bounds = el.getBoundingClientRect();
              const diff = Math.abs(bounds.top + bounds.height / 2 - middle);
              if (diff < bounds.height / 2) {
                el.style.opacity = '1';
              } else {
                el.style.opacity = '0.5';
              }
            });
          };
      
          container.addEventListener('scroll', handleScroll);
          return () => container.removeEventListener('scroll', handleScroll);
        }
      }, []);

    const links = [
    { url: "#", target: "_self", displayText: "PalmDigitalz" },
    { url: "#services", target: "_self", displayText: "Services" },
    { url: "#connect", target: "_self", displayText: "Connect" }
    ];

      
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>PalmDigitalz</title>
            <link rel="canonical" href="http://palmdigitalz.com" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="The Home of PalmDigitalz" />
            <meta name="keywords" content="PalmDigitalz, palmdigitalz, modernization services in India, modernization services in Chennai, modernization training center" />
            <link rel="icon" href={logo}></link>
        </Helmet>
        
            <div className="welcomeHome">
                <h1 id="gradientTextHome">Think. Do you really need to modernize?</h1>
            </div>
            <NavBar
            className="navigationBar"
            links={links}
            />
            {/* <Button 
            button={{
                class:"menuButton",
                onClick:closeAppointmentSchedulerPopup,
                displayText:"MENU"
            }}
            /> */}



        <section id="services">
            <div className="welcomeWords">
                <h1>Modernize.</h1>
                <h2>Don't Compromise.</h2>
                <h3>Reinvent your legacy.</h3>
            </div>


                <div className="mainServicesSection">
                    <div className="cardContainer">
                        <Card
                        card={{
                            imageSrc: modernizeImage,
                            imageAlt: "Graphic showing the modernization of technology",
                            tagline: "Modernization Assessment",
                            buttonText: "Learn more",
                            url: "/modernization-assessment"
                        }} />
                    </div>
                    <div className="cardContainer">
                        <Card
                        card={{
                            imageSrc: reverseEngImage,
                            imageAlt: "Graphic depicting what reverse engineering could look like",
                            tagline: "Reverse Engineering",
                            buttonText: "Learn more",
                            url: "/reverse-engineering"
                        }} />
                    </div>
                    <div className="cardContainer">
                        <Card
                        card={{
                            imageSrc: trainingImage,
                            imageAlt: "Graphic depicting training in app modernization",
                            tagline: "Modernization Training",
                            buttonText: "Learn more",
                            url: "/training"
                        }} />
                    </div>
                </div>
        </section>

            <div className="scrollableServicesSection">
                <div className="fixedLetters">
                    <h2>Re</h2>
                </div>
                <div className="scrollableWords">
                    <p>host</p>
                    <p>platform</p>
                    <p>purchase</p>
                    <p>architect</p>
                    <p>tire</p>
                    <p>tain</p>
                    <p>factor</p>
                </div>
            </div>


            <section id="connect">
            <div className="welcomeWords">
                <h2>Take the next step.</h2>
                <h3>Connect with a PalmDigitalz Expert</h3>
                {/* <Button
                button={{
                    class: "card-action",
                    displayText: "Pick a date",
                    onClick: openAppointmentSchedulerPopup
                }} 
                /> */}
            </div>
            {modalVisible && (
                <div className="modal">
                <Button 
                button={{
                    class:"card-action",
                    onClick:closeAppointmentSchedulerPopup,
                    displayText:"Close"
                }}
                />
                <h1>Schedule an appointment with the founder</h1>
                <a href="#services">Contact</a>
                <input type="email" placeholder="Business e-mail"></input>
                <label>
                    <input type="date" value={selectedDate} onChange={handleDateChange} />
                </label>
                <div>
                    {timeSlots.map((time, index) => (
                    <label key={index}>
                        <input
                        type="radio"
                        value={time}
                        checked={selectedTime === time}
                        onChange={handleTimeChange}
                        />
                        {time}
                    </label>
                    ))}
                </div>
                <br></br>
                <Button
                button={{
                    class:"card-action bookingBtn",
                    // Yet to define onClick method
                    displayText:"Book now"
                }}
                />
                </div>
            )}
            
            <div className="translucentSection">
                <div className="innerSection">
                    <h3>Over the phone.</h3>
                    <a href="tel: +91 93846 65078">
                    <Button
                    button={{
                        class: "card-action",
                        displayText: "+91 93846 65078"
                    }} />
                    </a>
                </div>
                <div className="innerSection">
                    <h3>Over in person.</h3>
                    <a target="_blank" rel="noreferrer" href="https://www.bing.com/maps?osid=c9106e4a-a979-4f4f-aafb-2c8c64f93ca3&cp=12.920057~80.126231&lvl=16&pi=0&imgid=b3fab76b-1077-448e-aac2-8a93c53dda74&v=2&sV=2&form=S00027">
                    <Button
                    button={{
                        class: "card-action mapBtn",
                        displayText: "Open on a map"
                    }} />
                    </a>
                </div>
            </div>
            <div className="translucentSection">
            <div className="innerSection">
                    <h3>Over email.</h3>
                    <a href="mailto: jos@palmdigitalz.com">
                    <Button
                    button={{
                        class: "card-action",
                        displayText: "jos@palmdigitalz.com"
                    }} />
                    </a>
                </div>
            </div>
            </section>


            
            <Footer />
        </>

    );
}

export default Home;