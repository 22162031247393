import { Helmet } from 'react-helmet';
import logo from '../logo.svg';
import '../styles/Training.css';
import Footer from "./Footer";
import NavBar from "./NavBar";
// import InfoCard from './reusables/InfoCard';
import Card from './interactables/Card';
import mod101 from '../img/modernization101.jpeg'
import modassess from '../img/modernizationassessmentworkshop.jpeg'
import reveng from '../img/reverseengineeringworkshop.jpeg'
import rehost from '../img/rehostworkshop.jpeg'
import replatform from '../img/replatformworkshop.jpeg'
import refactor from '../img/refactorworkshop.jpeg'
import rearchitect from '../img/rearchitectworkshop.jpeg'
import retire from '../img/retireadvancedworkshop.jpeg'
import digitalmarketing from '../img/digitialmarketing.jpeg'



function TrainingHome() {
    const links = [
        { url: "/", target: "_self", displayText: "Go Home" },
        // { url: "#recommended", target: "_self", displayText: "Recommended" },
        // { url: "#new", target: "_self", displayText: "New" },
        // { url: "#beginner", target: "_self", displayText: "Beginner" },
        // { url: "#advanced", target: "_self", displayText: "Advanced" },
        { url: "/", target: "_self", displayText: "🔙" }
    ];
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Application Modernization Training - PalmDigitalz</title>
            <link rel="canonical" href="http://palmdigitalz.com/training" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Advance your skills with our application modernization training. Master cloud development, microservices, and DevOps for digital success" />
            <meta name="keywords" content="application modernization training, modernization training programs, app modernization courses, cloud modernization workshops, enterprise modernization training, digital transformation education, modernization skills development, application modernization certification, modernization technology training, app modernization bootcamp, modernization strategy course, legacy system modernization training, cloud migration training, modernization project management education, software modernization training, application refactoring courses, containerization training, microservices architecture education, DevOps modernization training, application modernization best practices course, cloud-native development training, application modernization tools training, serverless computing education, modernization training for IT professionals, application modernization training for developers, modernization assessment training, application modernization training online, hybrid cloud modernization training, application modernization training for enterprises, modernization training for cloud services" />
            <link rel="icon" href={logo}></link>
        </Helmet>

            <div className="TrainingPageWrapper">
                
                <div className="TraininginngBody">
                    <div className="welcomeTraining">
                        <h1 id='gradientTextTraining'>Application Modernization Training</h1>
                    </div>
                    <NavBar
                    className="navigationBar"
                    links={links}
                    />
                    {/* <section id="recommended">
                        <div className="titlesForDisplay">
                            <h2>Recommended for you.</h2>
                        </div>
                        <div className='infoCardCarouselContainer'>
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization 101"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="App Rationalization Workshop"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Reverse Engineering Workshop"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                        </div>
                    </section> */}
                    <div className="mainServicesSection">
                        <div className="cardContainer">
                        <Card
                            card={{
                                imageSrc: mod101,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Modernization 101",
                                buttonText: "1 week",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                            <div className="cardContainer">
                            <Card
                            card={{
                                imageSrc: modassess,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Modernization Assessment Workshop",
                                buttonText: "1 week",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                            <div className="cardContainer">
                            <Card
                            card={{
                                imageSrc: reveng,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Reverse Engineering Workshop",
                                buttonText: "2 weeks",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                    </div>
                    <div className="mainServicesSection">
                    <div className="cardContainer">
                        <Card
                            card={{
                                imageSrc: rehost,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Rehost Workshop",
                                buttonText: "2 weeks",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                            <div className="cardContainer">
                            <Card
                            card={{
                                imageSrc: replatform,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Replatform Workshop",
                                buttonText: "1 week",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                            
                            <div className="cardContainer">
                            <Card
                            card={{
                                imageSrc: refactor,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Refactor Workshop",
                                buttonText: "2 weeks",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                        
                    </div>
                    <div className="mainServicesSection">
                    <div className="cardContainer">
                        <Card
                            card={{
                                imageSrc: rearchitect,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Rearchitect Workshop",
                                buttonText: "1 week",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                            <div className="cardContainer">
                            <Card
                            card={{
                                imageSrc: retire,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Retire Advanced Training",
                                buttonText: "1 week",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                            
                            <div className="cardContainer">
                            <Card
                            card={{
                                imageSrc: digitalmarketing,
                                imageAlt: "Graphic depicting what reverse engineering could look like",
                                tagline: "Digital Marketing",
                                buttonText: "2 weeks",
                                // url: "/reverse-engineering"
                            }} />
                            </div>
                        
                    </div>                   
                    {/* <section id="new">
                        <div className="titlesForDisplay">
                            <h2>New courses.</h2>
                        </div>
                        <div className='infoCardCarouselContainer'>
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                        </div>
                    </section>
                    <section id="beginner">
                        <div className="titlesForDisplay">
                            <h2>Beginner? No problem.</h2>
                        </div>
                        <div className='infoCardCarouselContainer'>
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                        </div>
                    </section>
                    <section id="advanced">
                        <div className="titlesForDisplay">
                            <h2>Turn up the heat with advanced courses.</h2>
                        </div>
                        <div className='infoCardCarouselContainer'>
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                            <InfoCard
                            image={modernizatioIntroCourseImg}
                            title="Modernization Introduction"
                            isBeginnerFriendly={true}
                            rating={4.5}
                            duration="1 week"
                            // onPopupOpen={handlePopupOpen}
                            />
                        </div>
                    </section> */}
        
                </div>
                <Footer />
            </div>
        </>
    )
}

export default TrainingHome;