import { Helmet } from 'react-helmet';
import logo from '../logo.svg';
import '../styles/ReverseEngineering.css';
import Footer from "./Footer";
import NavBar from "./NavBar";

function ReverseEngineeringHome() {
    const links = [
        { url: "#who", target: "_self", displayText: "Who" },
        { url: "#what", target: "_self", displayText: "What" },
        { url: "#when", target: "_self", displayText: "When" },
        // { url: "#where", target: "_self", displayText: "Where" },
        // { url: "#why", target: "_self", displayText: "Why" },
        { url: "#how", target: "_self", displayText: "How" },
        { url: "/", target: "_self", displayText: "🔙" }
    ];
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Reverse Engineering - PalmDigitalz</title>
            <link rel="canonical" href="http://palmdigitalz.com/reverse-engineering" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Enhance app performance and security with our reverse engineering services. Expert solutions for software analysis and optimization." />
            <meta name="keywords" content="reverse engineering services, application reverse engineering, reverse engineering experts, software reverse engineering, code reverse engineering, reverse engineering solutions, reverse engineering consultancy, reverse engineering support, reverse engineering techniques, reverse engineering tools, reverse engineering process, reverse engineering for applications, professional reverse engineering, reverse engineering assistance, reverse engineering strategies, reverse engineering methodologies, reverse engineering in software development, reverse engineering software tools, reverse engineering best practices, reverse engineering security analysis, reverse engineering for app security, reverse engineering malware analysis, reverse engineering code analysis, reverse engineering vulnerability assessment, reverse engineering and software testing, reverse engineering for software improvement, reverse engineering software architecture, reverse engineering legacy applications, reverse engineering for code optimization, reverse engineering for debugging, reverse engineering consultation" />
            <link rel="icon" href={logo}></link>
        </Helmet>

            <div className="ReverseEngineeringPageWrapper">

                <div className="ReverseEngineeringBody">
                    <div className="welcomeReverseEngineering">
                        <h1 id='gradientTextReverseEngineering'>Reverse Engineering</h1>
                    </div>
                    <NavBar
                    className="navigationBar"
                    links={links}
                    />
                        <section id="who">
                        <div id="whoDiv" className="mainServicesSection">
                            <div className="subHeading">
                                <h2 id="gradientTextReverseEngineering">Who?</h2>
                                <p>Zero or bad documentation</p>
                                <p>Need for modernization</p>
                                <p>Need for KT</p>
                            </div>
                        </div>
                    </section>
                    <section id="what">
                        <div id="whatDiv" className="mainServicesSection">
                            <div className="subHeading">
                                <h2 id="gradientTextReverseEngineering">What?</h2>
                                <p>Understand:</p>
                                <p>Static Code</p>
                                <p>Data</p>
                                <p>Architecture</p>
                            </div>
                        </div>
                    </section>
                    <section id="when">
                        <div id="whenDiv" className="mainServicesSection">
                            <div className="subHeading">
                                <h2 id="gradientTextReverseEngineering">When?</h2>
                                <p>To align:</p>
                                <p>Business drivers</p>
                                <p>Technology roadmap</p>
                            </div>
                        </div>
                    </section>
                    {/* <section id="where">
                        <div id="whereDiv"className="mainServicesSection">
                            <div className="subHeading">
                                <h2 id="gradientTextReverseEngineering">Where?</h2>
                                <p>Your Cloud</p>
                                <p>Our Cloud</p>
                            </div>
                        </div>
                    </section>
                    <section id="why">
                        <div id="whyDiv" className="mainServicesSection">
                            <div className="subHeading">
                                <h2 id="gradientTextReverseEngineering">Why?</h2>
                                <p>Flaws in preceding Application Modernization can cost Millions</p>
                            </div>
                        </div>
                    </section> */}
                    <section id="how">
                        <div id="howDiv" className="mainServicesSection">
                            <div className="subHeading">
                                <h2 id="gradientTextReverseEngineering">How?</h2>
                                <p>Manual approach</p>
                                <p>Hybrid approach</p>
                                <p>Automated approach</p>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ReverseEngineeringHome;