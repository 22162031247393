import { Helmet } from 'react-helmet';
import logo from '../logo.svg';
import '../styles/ModernizationAssessment.css';
import Footer from './Footer';
import NavBar from "./NavBar";

function ModernizationAssessmentHome() {
    
    const links = [
        { url: "#who", target: "_self", displayText: "Who" },
        { url: "#what", target: "_self", displayText: "What" },
        { url: "#when", target: "_self", displayText: "When" },
        { url: "#where", target: "_self", displayText: "Where" },
        { url: "#why", target: "_self", displayText: "Why" },
        { url: "#how", target: "_self", displayText: "How" },
        { url: "/", target: "_self", displayText: "🔙" }
    ];

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Modernization Assessment - PalmDigitalz</title>
            <link rel="canonical" href="http://palmdigitalz.com/modernization-assessment" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Optimize your IT with our modernization assessment. Get a custom roadmap for digital transformation and cloud migration." />
            <meta name="keywords" content="modernization assessment services, cloud readiness evaluation, application modernization analysis, rapid modernization assessment, cloud migration assessment, IT modernization review, digital transformation assessment, legacy system assessment, modernization strategy planning, modernization roadmap development, IT infrastructure evaluation, software modernization criteria, enterprise modernization assessment, technology upgrade analysis, modernization effort estimation, cloud journey assessment, hybrid cloud modernization, modernization business case, application portfolio analysis, modernization complexity assessment, app modernization services, application transformation, modernizing legacy apps, cloud-native app development, microservices architecture, containerization services, app modernization strategies, cloud application modernization, app refactoring services, modern app development, app modernization best practices, enterprise app modernization, app modernization roadmap, application re-platforming, app modernization tools, agile app modernization, app modernization consultancy, DevOps for app modernization, app scalability improvement, app modernization for business growth" />
            <link rel="icon" href={logo}></link>
        </Helmet>

        <div className="ModernizationAssessmentPageWrapper">
  

            <div className="ModernizationAssessmentBody">
                <div className="welcomeModernization">
                    <h1 id='gradientTextModAssess'>Modernization Assessment</h1>
                </div>
                <NavBar 
            className="navigationBar"
            links={links}
            />
                <section id="who">
                    <div id="whoDiv" className="mainServicesSection">
                        <div className="subHeading">
                            <h2 id="gradientTextModAssess">Who?</h2>
                            <p>Focused on CTO</p>
                            <p>Reducing cost / TCO</p>
                            <p>Reducing complexity</p>
                            <p>Improving efficiency</p>
                        </div>
                    </div>
                </section>
                <section id="what">
                    <div id="whatDiv" className="mainServicesSection">
                        <div className="subHeading">
                            <h2 id="gradientTextModAssess">What?</h2>
                            <p>Focused on IT Cost Optimization</p>
                            <p>Infrastructure</p>
                            <p>Apps</p>
                            <p>Software Licenses</p>
                        </div>
                    </div>
                </section>
                <section id="when">
                    <div id="whenDiv" className="mainServicesSection">
                        <div className="subHeading">
                            <h2 id="gradientTextModAssess">When?</h2>
                            <p>Reduce technical debt</p>
                            <p>Showcase immediate savings</p>
                            <p>Improved performance</p>
                        </div>
                    </div>
                </section>
                <section id="where">
                    <div id="whereDiv"className="mainServicesSection">
                        <div className="subHeading">
                            <h2 id="gradientTextModAssess">Where?</h2>
                            <p>Your Cloud</p>
                            <p>Our Cloud</p>
                        </div>
                    </div>
                </section>
                <section id="why">
                    <div id="whyDiv" className="mainServicesSection">
                        <div className="subHeading">
                            <h2 id="gradientTextModAssess">Why?</h2>
                            <p>Flaws in preceding Application Modernization can cost Millions</p>
                        </div>
                    </div>
                </section>
                <section id="how">
                    <div id="howDiv" className="mainServicesSection">
                        <div className="subHeading">
                            <h2 id="gradientTextModAssess">How?</h2>
                            <p>Traditional Approach</p>
                            <p>Data Points Driven Approach</p>
                            <p>Rationalization Consulting</p>
                        </div>
                    </div>
                </section>
            </div>
            
            <Footer />
            </div>
        </>
    );
}

export default ModernizationAssessmentHome;